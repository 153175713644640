<template>
  <v-container fluid class="hero">
    <div height="100%"></div>
    <!-- <v-carousel hide-delimiters>
    <v-carousel-item></v-carousel-item>
    <v-carousel-item></v-carousel-item>
    <v-carousel-item></v-carousel-item>
  </v-carousel> -->
    <!-- <div>
    <v-img
      lazy-src="../../assets/landing.png"
      max-height="100%"
      max-width="100%"
      src="../../assets/landing.png"
      contain
    ></v-img>
  </div> -->
  </v-container>
</template>

<script>
export default {
  components: {},
  name: "Landing",
  data() {
    return {
      // tab: 0,
    };
  },
  methods: {},
  computed: {},
};
</script>

<style scoped>
.hero {
  background: url("../../assets/landing.png") no-repeat center center fixed !important;
  background-size: cover;
  height: 100vh;
  width: 100vw;
}
</style>
